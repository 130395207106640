<template>
  <iframe
    v-resize
    src="https://widget.zola.com/v1/widget/registry/alexanderandlindsey2022/html?"
  />
</template>

<script>
export default {
  name: 'Registry',
};
</script>

<style lang="scss" scoped>
iframe {
  border: none;
  width: 100%;
  position: relative;
  overflow: hidden;
}
</style>
